.callWrapper{
    position: relative;
    height: calc(100% - 65px);
    background: var(--white) url('https://cdn.mastersunion.org/assets/imgV2/confrimCallBackground.png');

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.bigHeading{
    /* font-size: 48px;
    line-height: 120%; */
    font-size: 32px;
    line-height: 80%;
}

.callMidBox{
    text-align: center;
}

.text-2{
    font-size: 16px;
    line-height: 20px;
}

.scheduleWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
}

.pillBox{
    display: block;
    padding: 8px 15px;
    color: var(--white);
    background: var(--black-1);
    border-radius: 20px;
    position: relative;
}

.mentorBoxCall{
    background: #FAFAFA;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    display: block;
    max-width: 475px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 30px 50px;
}

.mentorBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.masterName{
    font-size: 20px;
    line-height: 120%;
    font-family: 'inter bold';
}

.masterDetail{
    font-size: 12px;
    line-height: 120%;
    text-align: left;
}

.calllink{
    font-size: 16px;
    line-height: 20px;
}

.calllink a{
    color: #3397EF;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.masterOtherDetails p{
    font-size: 13px;
    line-height: 150%;
    text-align: left;
}

.parent{
    cursor: pointer;
}