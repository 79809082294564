.uploadImageContainer{
    width: 400px;
    position: relative;
    border-radius: 5px;
    background: var(--white);
    overflow: hidden;
}

.uploadImageContainer .popupBtnGrp{
    padding: 0 var(--spacing-20px) var(--spacing-20px);
    text-align: right;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.uploadImageContainer .popupBtnGrp button{
    max-width: max-content;
}

.closeWhite{
    filter: invert(1);
    cursor: pointer;
}

.uploadImageContainer .popupBtnGrp button{
    padding: 10px 15px;
    font-size: 14px;
    line-height: 120%;
}

.uploadImageContainer .popupBtnGrp button:not(:first-child){
    margin-left: 10px;
}