.HighlightHeading{
    font-size: 16px;
    line-height: 26px;
    font-family: 'inter bold';
    color: #404040;
}

.headerSmall{
    padding: 15px 30px;
    background: var(--white);
    border-radius: 5px;
}

.tableContainer{
    position: relative;
    height: calc(100% - 65px);
    background: var(--white);
    overflow: auto;
}

/* .fullDetail{
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #fff;
    height: 100%;
} */

.studentTable{
    width: 100%;
    text-align: left;
    border-radius: 5px;
    overflow: hidden;
    background: var(--white);
}

.mentorProfileTh{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

thead{
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
}

table th{
    font-family: 'Inter Regular';
    background: #21222D;
    color: var(--white);
    padding: 15px 40px;
    font-weight: normal;
}

.studentTable th:not(:first-child){
    padding-left: 20px;
}

table td{
    font-family: 'Inter Regular';
    font-size: 16px;
    color: var(--black-3);
    border-bottom: 1px solid var(--grey-3);
    padding: 15px 20px;
}

.mentorContent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.mentorName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: #2B64B9;
    font-family: 'inter semi bold';
}

.mentorLink{
    line-height: 0;
}

.mentorDetail{
    font-size: 12px;
    line-height: 120%;
    color: var(--black-4);
}

.mentorDetails{
    position: relative;
    cursor: pointer;
}

.mentorDetails:hover .viewProfile{
    visibility: visible;
    opacity: 1;
    transition: 0.4s ease-in-out;
}

.viewProfile {
    position: absolute;
    left: 0;
    width: 100%;
    top: -40px;
    text-align: center;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in-out;
}

.viewProfile a::after{
    content: '';
    position: absolute;
    left: calc(50% - 4px);
    bottom: -10px;
    width: 10px;
    height: 10px;
    z-index: 1;
    background: #fff;
    transform: rotate(45deg);
}

.viewProfile a{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px 5px;
    text-decoration: none;
    font-size: 12px;
    line-height: 100%;
    color: var(--black-1);
    font-family: 'inter regular';
    z-index: 9;
}